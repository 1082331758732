.footer {
  margin-bottom: 4rem;
}

.footer_container {
  display: flex;
  flex-flow: row;
  padding: 1.5rem;
  column-gap: 1rem;
}

@media screen and (prefers-reduced-motion: no-preference) {
  .footer,
  .footer * {
    transition: all 200ms;
  }
}

.info {
  display: flex;
  flex-flow: column;
  row-gap: 1rem;
  width: 100%;
}

.logo {
  width: 3rem;
}

.social_media_logo {
  width: 2rem;
}

.links {
  display: flex;
  flex-flow: row;
  width: 100%;
}

.social_links {
  display: flex;
  flex-flow: row;
  column-gap: 1rem;
}

.social_links > a > img {
  width: 2rem;
}

.social_links > a:hover {
  transform: scale(1.1, 1.1);
}

.about_links {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  row-gap: 1rem;
  width: 100%;
}

.about_links > h2 {
  font-size: var(--font-xl);
}

.about_links > a:hover {
  transform: scale(1.1, 1.1);
  color: var(--primary-color);
}

.download_links {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  row-gap: 2rem;
  width: 100%;
}

.download_links > h2 {
  font-size: var(--font-xl);
}

.download_links > a {
  width: 100%;
}

.app_download {
  display: flex;
  flex-flow: row;
  column-gap: 1rem;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.app_download:hover {
  transform: scale(1.1, 1.1);
  color: var(--primary-color);
}

.app_download > span {
  width: 100%;
}

.copyright {
  text-align: center;
}

@media screen and (max-width: 1200px) {
  .info > p {
    font-size: var(--font-sm);
  }

  .about_links > h2 {
    font-size: var(--font-lg);
  }

  .about_links > a {
    font-size: var(--font-sm);
  }

  .download_links > h2 {
    font-size: var(--font-lg);
  }

  .app_download > span {
    font-size: var(--font-sm);
  }
}

@media screen and (max-width: 500px) {
  .footer_container {
    flex-flow: column;
    column-gap: unset;
    row-gap: 2rem;
    margin-bottom: 4rem;
  }

  .info {
    align-items: center;
  }
}
