@import-normalize;

:root {
  /* Colors */
  --primary-color: #436ab3;
  --secondary-color: rgba(55, 65, 81, 1);

  /* Font Them */
  --font-sm: 0.875rem;
  --font-lg: 1.125rem;
  --font-xl: 1.25rem;
  --font-2xl: 1.5rem;
  --font-4xl: 2.25rem;

  /* Shadows */
  --card-shadow: 0 0 #000, 0 0 #000, 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  --card-shadow-hover: 0 0 #000, 0 0 #000, 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
}

html {
  box-sizing: border-box;
  scroll-behavior: smooth;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  width: 65%;
  margin: 0 auto 0 auto;
}

@media screen and (max-width: 800px) {
  body {
    width: 100%;
  }
}

a {
  text-decoration: none;
  color: inherit;
}

@media (prefers-color-scheme: dark) {
  :root {
    --secondary-color: #fff;
  }

  body {
    background-color: #181818;
    color: #fff;
  }

  .dark_mode_svg {
    fill: #fff;
  }
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 300ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.ReactModal__Body--open {
  overflow: hidden;
}
