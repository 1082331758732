.contact_us {
  display: flex;
  flex-flow: row;
  column-gap: 2rem;
  align-items: center;
  justify-items: center;
  margin: 1rem;
  padding: 4rem;
  border-radius: 1rem;
  background-color: white;
}

@media screen and (prefers-reduced-motion: no-preference) {
  .contact_us,
  .contact_us * {
    transition: all 200ms;
  }
}

.contact_us_form > h1 {
  font-size: var(--font-4xl);
}

.contact_us_form > form {
  margin-top: 2rem;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  justify-content: flex-start;
  row-gap: 3rem;
}

.field {
  display: flex;
  flex-flow: column-reverse;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}

.contact_us_form > form input,
.contact_us_form > form label,
.contact_us_form > form select {
  outline: none;
  position: relative;
  width: 100%;
}

.contact_us_form > form label {
  padding-left: 0.5rem;
  z-index: 1;
  font-size: var(--font-lg);
  top: 25px;
}

.contact_us_form > form input {
  padding: 0.5rem;
  font-size: var(--font-lg);
  background: transparent;
  border: none;
  border-bottom: 2px solid #000;
}

.contact_us_form > form select {
  padding: 0.5rem;
  font-size: var(--font-lg);
  background: transparent;
  border: none;
  border-bottom: 2px solid #000;
}

.contact_us_form > form input:focus ~ label,
.contact_us_form > form input:not(:placeholder-shown) ~ label {
  top: 0;
  font-size: var(--font-sm);
}

.contact_us_form > form button {
  background: none;
  color: inherit;
  border: none;
  font: inherit;
  cursor: pointer;
  outline: inherit;

  align-self: center;
  width: min-content;

  background-color: var(--primary-color);
  color: #fff;
  padding: 1rem 2rem 1rem 2rem;
  border-radius: 0.5rem;
}

.contact_us_form > form button:hover {
  transform: scale(1.1, 1.1);
  box-shadow: var(--card-shadow-hover);
}

.contact_us_form > form button:focus {
  transform: unset;
  box-shadow: unset;
}

.crew_app_info {
  display: flex;
  flex-flow: column;
  row-gap: 1rem;
  justify-content: center;
  align-items: center;
}

.crew_logo {
  width: 4rem;
}

.crew_app_info > .mobile_links {
  display: flex;
  flex-flow: column;
  row-gap: 1rem;
  align-items: center;
}

.crew_app_info > .mobile_links > a > svg {
  width: 12rem;
  height: auto;
}

.crew_app_info > .web_link {
  display: flex;
  flex-flow: column;
  row-gap: 1rem;
  align-items: center;
}

.crew_app_info > .web_link > .crew_web_app_button {
  color: #fff;
  padding: 1rem 2rem 1rem 2rem;
  background-color: var(--primary-color);
  border-radius: 0.5rem;
}

.contact_us > .crew_logo {
  display: none;
  width: 4rem;
}

@media (prefers-color-scheme: dark) {
  .contact_us {
    background-color: #181818;
  }

  .contact_us_form > form input {
    color: #fff;
    border-bottom: 2px solid #fff;
  }

  .contact_us_form > form select {
    color: #fff;
    border-bottom: 2px solid #fff;
    background-color: #181818;
  }
}

@media screen and (max-width: 1200px) {
  .contact_us_form > form label,
  .contact_us_form > form input,
  .contact_us_form > form select {
    font-size: var(--font-sm);
  }

  .contact_us_form > h1 {
    font-size: var(--font-2xl);
  }
}

@media screen and (max-width: 800px) {
  .contact_us_form > form {
    row-gap: 2rem;
  }

  .contact_us {
    padding: 3rem;
  }
}

@media screen and (max-width: 600px) {
  .contact_us {
    padding: 2rem;
    flex-flow: column;
  }

  .contact_us > .crew_logo {
    display: unset;
  }

  .crew_app_info > .mobile_links {
    display: none;
  }

  .crew_app_info > .crew_logo {
    display: none;
  }
}
