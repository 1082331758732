.card {
  display: flex;
  flex-flow: column;
  border-radius: 0.75rem;
  justify-content: flex-start;
  align-items: center;
  row-gap: 1rem;
  width: 100%;
}

@media screen and (prefers-reduced-motion: no-preference) {
  .card,
  .card * {
    transition: all 200ms;
  }
}

.card:hover {
  transform: scale(1.1, 1.1);
}

.image {
  width: 75%;
  height: auto;
}

.card > h1 {
  font-size: var(--font-2xl);
  font-weight: 700;
  color: var(--secondary-color);
  text-align: center;
  line-height: 2rem;
}

@media screen and (max-width: 1300px) {
  .card {
    row-gap: 2rem;
  }

  .card > h1 {
    font-size: var(--font-lg);
    line-height: 1.5rem;
  }

  .image {
    width: 90%;
    height: auto;
  }
}

@media screen and (max-width: 500px) {
  .card {
    flex-flow: row;
    column-gap: 2rem;
    row-gap: unset;
    padding: 2rem;
  }

  .card > h1 {
    width: 70%;
    text-align: start;
  }

  .image {
    width: 90%;
    height: auto;
  }
}
