.container {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  height: 700px;
}

.container_reverse {
  flex-flow: row-reverse;
}

.info_container {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  justify-content: center;
}

.info_container_reverse {
  align-items: flex-end;
  text-align: end;
}

.title {
  font-size: var(--font-4xl);
  font-weight: 700;
}

.info {
  width: 70%;
  color: var(--secondary-color);
  font-size: var(--font-xl);
}

@media screen and (max-width: 800px) {
  .container {
    flex-flow: column-reverse;
    height: auto;
    margin: 2rem;
  }

  .info_container {
    align-items: center;
    text-align: center;
  }

  .info {
    width: 100%;
  }
}
