.cardlist {
  margin-bottom: 2.5rem;
  display: flex;
  flex-flow: row;
  column-gap: 6rem;
  justify-content: space-between;
  width: 100%;
}

@media screen and (max-width: 1300px) {
  .cardlist {
    flex-flow: row;
    column-gap: 3rem;
  }
}

@media screen and (max-width: 800px) {
  .cardlist {
    padding: 0 3rem 0 3rem;
  }
}

@media screen and (max-width: 500px) {
  .cardlist {
    flex-flow: column;
    height: auto;
    row-gap: 2rem;
    padding: 0;
  }
}
