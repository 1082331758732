.join_us {
  display: flex;
  flex-flow: column;
  background-color: #3f51b5;
  width: 80%;
  border-radius: 1rem;
  padding: 2rem;
  margin: 0 auto 0 auto;
}

@media screen and (prefers-reduced-motion: no-preference) {
  .join_us,
  .join_us * {
    transition: all 200ms;
  }
}

.info {
  width: 100%;
  color: #fff;
}

.info > h1 {
  font-size: var(--font-4xl);
}

.join_us_button {
  display: flex;
  flex-flow: column;
  width: 100%;
  align-items: flex-end;
  justify-content: flex-end;
}

.join_us_button > a {
  background: none;
  color: inherit;
  border: none;
  font: inherit;
  cursor: pointer;
  outline: inherit;

  background-color: #fff;
  color: #303030;
  padding: 1rem 2rem 1rem 2rem;
  border-radius: 0.5rem;
}

.join_us_button > a:hover {
  transform: scale(1.1, 1.1);
  box-shadow: var(--card-shadow-hover);
}

.join_us_button > a:focus {
  transform: unset;
  box-shadow: unset;
}

@media screen and (max-width: 1500px) {
  .join_us {
    width: 100%;
  }
}

@media screen and (max-width: 1200px) {
  .info > h1 {
    font-size: var(--font-2xl);
  }
}

@media screen and (max-width: 800px) {
  .join_us {
    border-radius: unset;
  }
}

@media screen and (max-width: 600px) {
  .join_us_button {
    align-items: flex-start;
    justify-content: center;
  }
}

.ack_container {
  display: flex;
  flex-flow: column;

  margin: 1rem;
  padding: 3rem;
  border-radius: 1rem;
  background-color: white;
}

@media screen and (prefers-reduced-motion: no-preference) {
  .ack_container,
  .ack_container * {
    transition: all 200ms;
  }
}

.ack_container > h1 {
  font-size: var(--font-4xl);
}

.ack_container > p {
  font-size: var(--font-lg);
}

.ack_container > button {
  margin-top: 1rem;
  background: none;
  color: inherit;
  border: none;
  font: inherit;
  cursor: pointer;
  outline: inherit;

  align-self: flex-end;

  background-color: var(--primary-color);
  color: #fff;
  padding: 1rem 2rem 1rem 2rem;
  border-radius: 0.5rem;
}

.ack_container > button:hover {
  transform: scale(1.1, 1.1);
  box-shadow: var(--card-shadow-hover);
}

.ack_container > button:focus {
  transform: unset;
  box-shadow: unset;
}

@media (prefers-color-scheme: dark) {
  .ack_container {
    background-color: #181818;
  }
}

@media screen and (max-width: 1200px) {
  .ack_container > h1 {
    font-size: var(--font-2xl);
  }

  .ack_container > p {
    font-size: var(--font-sm);
  }
}

@media screen and (max-width: 800px) {
  .ack_container {
    padding: 2rem;
  }
}
