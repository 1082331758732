.container {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  margin-top: 4rem;
}

@media screen and (prefers-reduced-motion: no-preference) {
  .container,
  .container * {
    transition: all 200ms;
  }
}

.container > picture > img {
  width: 100%;
  height: auto;
}

.info_container {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  justify-content: center;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 4rem;
}

.info_container > h1 {
  width: 70%;
  font-size: var(--font-4xl);
  font-weight: 700;
}

.info_container > a > svg {
  width: 12rem;
  height: auto;
}

.info_container > a:hover {
  transform: scale(1.1, 1.1);
}

@media screen and (max-width: 1300px) {
  .info_container > h1 {
    width: 100%;
  }
}

@media screen and (max-width: 1200px) {
  .info_container > h1 {
    font-size: var(--font-2xl);
  }
}

@media screen and (max-width: 800px) {
  .container {
    flex-flow: column-reverse;
    height: auto;
    margin-left: 2rem;
    margin-right: 2rem;
    margin-bottom: 2rem;
  }

  .container > picture > img {
    width: 100%;
    height: auto;
  }

  .info_container > a > svg {
    width: 10rem;
    height: auto;
  }

  .info_container {
    align-items: center;
    text-align: center;
    padding-left: unset;
    padding-right: unset;
    row-gap: 2rem;
  }
}
