.nav {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
}

@media screen and (prefers-reduced-motion: no-preference) {
  .nav,
  .nav * {
    transition: all 200ms;
  }
}

.logo {
  width: 2rem;
  height: 2rem;
}

.nav_list {
  display: flex;
  flex-flow: row;
  column-gap: 1.5rem;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.nav_item {
  padding: 8px 0 8px 0;
  color: var(--secondary-color);
  font-size: var(--font-lg);
}

.nav_item:hover {
  color: var(--primary-color);
  transform: scale(1.1, 1.1);
}

.nav_selected {
  color: var(--primary-color);
}

@media screen and (max-width: 1200px) {
  .nav_item {
    font-size: var(--font-sm);
  }
}

@media screen and (max-width: 800px) {
  .nav {
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;
  }

  .nav_list {
    display: none;
  }
}
