.discount_card {
  display: flex;
  flex-flow: column;
  background-color: #3f51b5;
  width: 80%;
  border-radius: 1rem;
  padding: 2rem;
  margin: 2rem auto 0 auto;
}

@media screen and (prefers-reduced-motion: no-preference) {
  .discount_card,
  .discount_card * {
    transition: all 200ms;
  }
}

.info {
  width: 100%;
  color: #fff;
}

.info > h1 {
  font-size: var(--font-4xl);
}
.info > p {
  font-size: var(--font-lg);
}

.book_appointment {
  display: flex;
  flex-flow: column;
  width: 100%;
  align-items: flex-end;
  justify-content: flex-end;
}

.book_appointment_button {
  background-color: #fff;
  color: #303030;
  padding: 1rem 2rem 1rem 2rem;
  border-radius: 0.5rem;
}

.book_appointment_button:hover {
  transform: scale(1.1, 1.1);
  box-shadow: var(--card-shadow-hover);
}

@media screen and (max-width: 1500px) {
  .discount_card {
    width: 100%;
  }
}

@media screen and (max-width: 1200px) {
  .info > h1 {
    font-size: var(--font-2xl);
  }
  .info > p {
    font-size: var(--font-sm);
  }
}

@media screen and (max-width: 800px) {
  .discount_card {
    border-radius: unset;
  }
}

@media screen and (max-width: 600px) {
  .book_appointment {
    align-items: flex-start;
    justify-content: center;
  }
}
